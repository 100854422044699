<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <validation-observer ref="leadRules">
      <b-form @submit.prevent>
        <b-row class="invoice-preview">
          <b-col cols="12">
            <b-card>
              <b-row>
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Lead Details</th>
                    </tr>
                  </thead>
                </table>
              </b-row>

              <b-row class="mt-1">
                <b-col cols="6">
                  <b-form-group v-if="id == null || leadData.source == 'user'" label="Reason*" label-for="h-reason">
                    <validation-provider name="Reason" #default="{ errors }" rules="required">
                      <v-select v-model="leadData.reason" :options="reasons" label="reason" :clearable="false"> </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group v-else label="Reason" label-for="h-reason">
                    <b-form-input v-model="leadData.reason" disabled />
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group class="mr-1" label="Assigned User" label-for="h-user">
                    <validation-provider name="Assigned User" #default="{ errors }" rules="required">
                      <v-select v-model="leadData.userId" :taggable="true" :options="users" label="value" :value="leadData.userId" :reduce="(val) => val.userId" :clearable="false"> </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <fieldset>
                <h6>Customer Details</h6>
                <b-row>
                  <!-- name -->
                  <b-col cols="6">
                    <b-form-group label="Name*" label-for="h-name">
                      <validation-provider name="Name" #default="{ errors }" rules="required">
                        <b-form-input v-model="leadData.firstName" id="h-name" placeholder="Name" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- surname -->
                  <b-col cols="6">
                    <b-form-group label="Surname" label-for="h-surname">
                      <b-form-input v-model="leadData.lastName" id="h-surname" placeholder="Surname" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6" v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                    <b-form-group label="Driver's License Number" label-for="h-license">
                      <b-form-input v-model="leadData.driversLicense" id="h-name" placeholder="Driver's License" />
                    </b-form-group>
                  </b-col>
                  <!-- surname -->
                  <b-col cols="6" v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                    <b-form-group label="Expiration Date" label-for="h-expiration">
                      <flat-pickr id="h-expiration" class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="leadData.expiration" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="6" v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                    <b-form-group label="Date of Birth" label-for="h-date">
                      <flat-pickr id="h-dateOfBirth" class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="leadData.dateOfBirth" />
                    </b-form-group>
                  </b-col>
                  <!-- surname -->
                  <b-col cols="6" v-if="leadData.reason == 'TO LEASE A VEHICLE'">
                    <b-form-group label="Social Security Number" label-for="h-ssn">
                      <b-form-input v-model="leadData.socialSecurity" id="h-ssn" placeholder="Social Security Number" />
                    </b-form-group>
                  </b-col>

                  <!-- email -->
                  <b-col cols="4">
                    <b-form-group label="Email" label-for="d-email">
                      <validation-provider name="Email" #default="{ errors }" rules="email">
                        <b-form-input v-model="leadData.email" id="d-email" placeholder="Email" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- phone -->
                  <b-col cols="4">
                    <b-form-group label="Phone" label-for="d-phone">
                      <b-form-input v-model="leadData.phone" id="d-phone" placeholder="Phone" />
                    </b-form-group>
                  </b-col>

                  <!-- Preferred Contact Method -->
                  <b-col cols="4">
                    <b-form-group label="Method*" label-for="h-method">
                      <validation-provider name="Method" #default="{ errors }" rules="required">
                        <v-select v-model="leadData.method" :options="methods" label="method" :clearable="false"> </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group label="Message*" label-for="h-message">
                      <validation-provider name="Message" #default="{ errors }" rules="required">
                        <b-form-textarea v-model="leadData.message" id="message" placeholder="Message" rows="3" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </fieldset>

              <!-- Buttons -->
              <b-row>
                <!-- submit and reset -->
                <b-col cols="12">
                  <b-button @click.prevent="formSubmitted" type="submit" variant="primary" class="mr-1 float-right">
                    Save
                  </b-button>

                  <b-button @click.prevent="complete" v-if="id != null && logged" type="submit" variant="success" class="mr-1 float-right">
                    Complete
                  </b-button>

                  <b-button @click.prevent="formSubmitted" v-if="id != null && logged" type="submit" variant="primary" class="mr-1 float-right">
                    Mark In Progress
                  </b-button>
                </b-col>
              </b-row>
              <!-- Buttons Finish -->
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import router from '@/router';
import leadStoreModule from './leadStoreModule';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import { required, email } from '@validations';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import Cleave from 'vue-cleave-component';

export default {
  components: {
    Cleave,
    vSelect,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    router,
    store,
    leadStoreModule,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
    uppercase: {
      update(element) {
        element.value = element.value;
      },
    },
  },

  setup() {
    const LEAD_APP_STORE_MODULE_NAME = 'lead';
    // Register module
    if (!store.hasModule(LEAD_APP_STORE_MODULE_NAME)) store.registerModule(LEAD_APP_STORE_MODULE_NAME, leadStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LEAD_APP_STORE_MODULE_NAME)) store.unregisterModule(LEAD_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    var leadDataModel = {
      channel: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      method: null,
      message: null,
      quote: null,
      solution: null,
      reason: null,
      responseDate: null,
      responseMethod: null,
      note: null,
    };

    var localUser = JSON.parse(localStorage.getItem('userData'));

    return {
      dropdownLoading: true,
      logged: false,
      localUser,
      responseDate: null,
      responseMethod: null,
      note: null,
      interactionLogs: { leadId: null, responseMethod: null, responseDate: null, note: null },
      users: [],
      leadOptions: [],
      loading: true,
      leadData: leadDataModel,
      id: null,
      required,
      email,
      methods: ['PHONE', 'EMAIL', 'SMS', 'LETTER', 'WEBSITE'],
      reasons: ['VBA PROJECT', 'WEB PROJECT', 'OTHER'],
      vehicles: [],
      solutions: ['MEETING', 'SCOPED', 'AGREED', 'PROVIDED INFORMATION'],
      sales: [],
      purchases: [],

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  methods: {
    createNewDropdownValue(val) {
      this.dropdownLoading = true;

      if (this.leadOptions.find((x) => x == val) == undefined) {
        store.dispatch('lead/saveLeadChannel', { title: val.trim() }).then((response) => {
          this.leadOptions.unshift(response.data);
          this.leadData.channel = response.data;
          this.dropdownLoading = false;
        });
      }
    },

    formSubmitted() {
      this.$refs.leadRules.validate().then((success) => {
        if (success) {
          this.loading = true;

          if (this.id != null) {
            if (this.leadData.status == 'A') {
              this.leadData.status == 'B';
            }

            store.dispatch('lead/updateLead', this.leadData).then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Lead has been updated',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getByIdLead();
            });
          } else {
            this.leadData.status = 'B';
            store
              .dispatch('lead/saveLead', this.leadData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Lead Insert Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });
                router.push({ name: 'leads-preview', params: { id: response.data.id } });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      });
    },

    complete() {
      this.$refs.leadRules.validate().then((success) => {
        if (success && this.leadData.solution != null) {
          this.loading = true;
          this.leadData.status = 'D';
          store.dispatch('lead/updateLead', this.leadData).then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Lead has been completed',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            this.getByIdLead();
          });
        } else if (this.leadData.solution == null) {
          this.$swal({
            title: 'Please enter a solution!',
            text: 'Lead can not be completed without a solution.',
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        }
      });
    },

    interactionLog() {
      this.loading = true;
      this.interactionLogs.leadId = this.id;
      this.interactionLogs.status = this.leadData.status;

      store
        .dispatch('lead/interactionLog', this.interactionLogs)
        .then((response) => {
          this.interactionLogs = { leadId: null, responseDate: null, responseMethod: null, note: null };
          this.logged = true;
          this.getByIdLead();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Interaction log has been saved',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Please try again or report an issue to support',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          });
        });
    },

    getByIdLead() {
      if (router.currentRoute.params.id) {
        this.id = router.currentRoute.params.id;
        store
          .dispatch('lead/getLeadById', { id: router.currentRoute.params.id })
          .then((response) => {
            this.leadData = response.data;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            router.push({ name: 'leads-list' });
          });
      } else {
        this.loading = false;
      }
    },

    getUsers() {
      store
        .dispatch('lead/fetchUsers', [])
        .then((res) => {
          res.data.user.forEach((element) => {
            if (element != null) {
              this.users.push({
                userId: Number(element.id),
                value: String(element.name + ' ' + element.surname),
              });
            }
          });
          this.users.push({
            userId: Number(0),
            value: 'All Users',
          });

          this.users = this.users.reverse();

          if (!this.$Can('lead_assign')) {
            this.leadData.userId = this.localUser.id;
          }

          this.leadOptions = res.data.channels;

          this.dropdownLoading = false;
          this.getByIdLead();
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'User list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },

    varicon(val) {
      if (val == 'SMS') {
        return 'MessageSquareIcon';
      } else if (val == 'PHONE') {
        return 'PhoneCallIcon';
      } else if (val == 'EMAIL') {
        return 'MailIcon';
      } else if (val == 'LETTER') {
        return 'FileTextIcon';
      } else {
        return 'InboxIcon';
      }
    },

    varyant(val) {
      if (val == 'A') {
        return 'secondary';
      } else if (val == 'B') {
        return 'warning';
      } else if (val == 'C') {
        return 'primary';
      } else if (val == 'D') {
        return 'success';
      } else if (val == 'E') {
        return 'info';
      }
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },

    validationForm1() {
      return new Promise((resolve, reject) => {
        this.$refs.leadRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },

  formatPrice(value, val) {
    if (value != null) {
      let val = (value / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  },

  created() {
    this.getUsers();
    // this.getPurchase();
    // this.getSale();
  },
  watch: {
    'leadData.reason': {
      handler: function(id, before) {
        if (this.leadData.id == null) {
          this.leadData.stockNumber = null;
          this.leadData.solution = null;
        }
      },
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
